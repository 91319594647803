import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { GatsbyImage } from "gatsby-plugin-image"
import parse from "html-react-parser"
import Projects from '../components/projects'
import Accordion from "react-bootstrap/Accordion"

const HomePage = props => {
  // const siteTitle = data.site.siteMetadata.title
  
  let {
    location,
    data: {
      wpPage: {
        excerpt,
        content,
        featuredImage: {
          node: {
            altText,
            localFile: {
              childImageSharp: { gatsbyImageData },
            },
          },
        },
        acf_home: {
          selectedProjects: {
            label,
            heading,
            projects
          },
          whatSetsUsApart: {
          copy,
          questions
        }
        },
        seo,
        contentTypeName,
        slug,
      },
    },
  } = props

  // console.log(questions)

  return (
    <Layout location={location}>
      <Seo bodyClasses={`${contentTypeName} ${slug}`} seo={seo} />
      <section className="intro">
        {gatsbyImageData && (
          <GatsbyImage image={gatsbyImageData} alt={altText} loading="eager" />
        )}
        <div className="content white">
          <div className="container">
            <div className="row">
              <div className="col-md-4 copy">
                {parse(content)}
              </div>
              <div className="col-md-12">
                <p className="last">
                  <a href="#work" className="link white">
                    See our work
                    <span className="im im-angle-down"></span>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="intro" className="white">
        <div className="container">
          <div className="row">
            <div className="col-md-10 col-lg-10 col-xl-9 col-xxl-8">
              {parse(excerpt)}
            </div>
          </div>
        </div>
      </section>
      <section id="work" className="white">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <blockquote>{label}</blockquote>
              <h2>{heading}</h2>
            </div>
          </div>
        </div>
      </section>
      <section id="projects" className="white projects">
        <div className="container">
          <div className="row">
            <Projects data={projects} />
          </div>
        </div>
      </section>
      <section id="faqs" className="white faqs">
        <div className="container">
          <div className="row">
            <div className="col-md-12">{parse(copy)}</div>
            <div className="col-md-12">
              <Accordion defaultActiveKey="0" flush>
                {questions.map((post, index) => {
                  const title = post.question
                  // console.log(post)
                  return (
                    <Accordion.Item eventKey={index} key={index}>
                      <Accordion.Header>{title}</Accordion.Header>
                      <Accordion.Body>{post.answer}</Accordion.Body>
                    </Accordion.Item>
                  )
                })}
              </Accordion>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default HomePage

export const pageQueryHome = graphql`
  {
    wpPage(isFrontPage: { eq: true }) {
      title
      excerpt
      content
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      acf_home {
        selectedProjects {
          label
          heading
          projects {
            ... on WpProject {
              databaseId
              slug
              title
              excerpt
              content
              uri
              featuredImage {
                node {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
              acf_projects {
                projectDetails {
                  title
                  description
                }
              }
            }
          }
        }
        whatSetsUsApart {
          copy
          questions {
            answer
            question
          }
        }
      }
      seo {
        ...WpYoastSEO
      }
      slug
      contentTypeName
    }
  }
`
