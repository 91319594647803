import parse from "html-react-parser"
import React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
// import Swiper core and required modules
import SwiperCore, {
  EffectFade,
  HashNavigation,
  Navigation,
  Pagination,
  Autoplay,
} from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
// Import Swiper styles
import "swiper/css";
import "swiper/css/autoplay"
import "swiper/css/navigation"

// install Swiper modules
SwiperCore.use([
  Navigation,
  Pagination,
  HashNavigation,
  Autoplay,
  EffectFade,
])

export default function Projects({ data }) {
  // const { data, cssClass } = props

  // console.log(data)

  return (
    <Swiper
      // navigation={true}
      // autoHeight="false"
      // effect="fade"
      // coverflowEffect={{
      //     rotate: 50,
      //     stretch: 0,
      //     depth: 100,
      //     modifier: 1,
      //     slideShadows: true,
      //   }}
      grabCursor={true}
      // fadeEffect={{ crossFade: true }}
      // initialSlide={3}
      loop={true}
      breakpoints={{
        // when window width is >= 320px
        320: {
          slidesPerView: 1.11,
          spaceBetween: 10,
        },
        768: {
          slidesPerView: 2.15,
          spaceBetween: 20,
        },
        // when window width is >= 640px
        960: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
      }}
      centeredSlides={false}
      // effect="overflow"
      autoplay={{
        delay: 5000,
        pauseOnMouseEnter: true,
        disableOnInteraction: false,
      }}
      speed={1500}
      lazy={true}
      navigation={true}
      // hashNavigation={true}
      // hashNavigation={{
      //   replaceState: true,
      //   watchState: true,
      // }}
      // pagination={{ clickable: true }}
      // navigation={{
      //   nextEl: ".swiper-news-next",
      //   prevEl: ".swiper-news-prev",
      // }}
      // onSwiper={swiper => console.log(swiper)}
      // onSlideChange={() => console.log("slide change")}
    >
      {data &&
        data.map((post, index) => {
          // let content = post.text
          // console.log(post)
          let featuredImage =
            post?.featuredImage?.node?.localFile?.childImageSharp
              ?.gatsbyImageData
          let altText = post?.featuredImage?.node?.altText || ""
          return (
            <SwiperSlide
              key={post?.databaseId}
              data-hash={`show-${post?.slug}`}
              id={`post-${post?.slug}`}
              className="col-md-4"
            >
              <Link to={post.uri} className="post col-md-4" key={index}>
                <div className="row">
                  <div className="col-md-12">
                    {!!featuredImage && (
                      <GatsbyImage image={featuredImage} alt={altText} />
                    )}
                  </div>
                  <div className="col-md-12 col-lg-12 col-xl-11 col-xxl-10">
                    <h4>{post.title}</h4>
                    {parse(post.excerpt)}
                  </div>
                </div>
              </Link>
            </SwiperSlide>
          )
        })}
    </Swiper>
  )
}
